import styled, { css } from 'styled-components';

const orderedBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

const isFromBreakpoint = (currentBP: string, targetBP?: string) => {
  if (!targetBP) return false;

  const currentIdx = orderedBreakpoints.findIndex((value) => value === currentBP);
  const targetIdx = orderedBreakpoints.findIndex((value) => value === targetBP);

  return currentIdx <= targetIdx;
};

const getBreakpointPadding = (
  currentBP: string,
  props: {
    breakpoint?: string;
    disables?: string[];
    theme: { metrics: { containers: { padding: { [key: string]: string } } } };
  },
) =>
  !props.disables?.includes(currentBP || '') && !isFromBreakpoint(currentBP, props.breakpoint)
    ? props.theme.metrics.containers.padding[currentBP]
    : 'unset';

const StyledContainer = styled.div<{
  breakpoint?: string;
  disables?: string[];
  isChinese?: boolean;
  open?: boolean;
  isHkEn?: boolean;
  isV2?: boolean;
}>`
  padding-left: ${(props) => getBreakpointPadding('xs', props)};
  padding-right: ${(props) => getBreakpointPadding('xs', props)};
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${(props) => props.theme.metrics.breakpoints.sm}) {
    ${(props) =>
      props.isChinese &&
      css`
        padding-top: ${props.open ? '46px' : '100px'};
      `}

    ${(props) =>
      props.isHkEn &&
      css`
        padding-top: ${props.open ? 0 : '42px'};
      `}
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.sm}) {
    padding-left: ${(props) => getBreakpointPadding('sm', props)};
    padding-right: ${(props) => getBreakpointPadding('sm', props)};
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.md}) {
    padding-left: ${(props) => getBreakpointPadding('md', props)};
    padding-right: ${(props) => getBreakpointPadding('md', props)};
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
    padding-left: ${(props) => getBreakpointPadding('lg', props)};
    padding-right: ${(props) => getBreakpointPadding('lg', props)};
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.xl}) {
    width: ${(props) => !props.disables?.includes('xl') && '1184px'};
    padding-left: ${(props) => getBreakpointPadding('xl', props)};
    padding-right: ${(props) => getBreakpointPadding('xl', props)};
  }

  /* @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.xxl}) {
    width: ${(props) => props.isV2 && '1600px'};
    padding-left: ${(props) => getBreakpointPadding('xl', props)};
    padding-right: ${(props) => getBreakpointPadding('xl', props)};
  } */
`;

export default StyledContainer;
