import React, { FC } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { ENV_URL } from '@source/constants/common';
import MultiLanguage from '@source/pages/Home/components/MultiLanguage';
import { useTranslation } from 'react-i18next';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { useRouter } from 'next/router';
import i18next from 'i18next';
import staticImgs from '@source/constants/staticImg';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { MenuItem, TLanguageKey, TRegionKey } from '@source/interface';
import useMobileView from '@design-system/hooks/useMobileView';
import StyledContainer from '@design-system/styled/Container';

import PrimaryMobileNavSubmenu from './PrimaryMobileNavSubmenu';

export interface MobileNavbarProps {
  showDrawer: () => void;
}

const StyleWrapper = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 50px;
  background-color: ${(props) => props.theme.color.background};
  box-shadow: ${(props) => props.theme.color.boxShadow.cardRaiseMiddleShadow};
`;

const StyleMenuLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mobile-nav-hamburger {
    padding: 0;
    background-color: unset;
    border: unset;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledRightItems = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .mobile-navbar-a {
    display: block;
    height: 100%;
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    ${(props) => props.theme.typo.style.mainButtonOrLink}
    ${(props) => props.theme.typo.familyGoogle.semiBold};

    &:hover {
      color: ${(props) => props.theme.color.primaryBase};
    }

    &.active {
      color: ${(props) => props.theme.color.primaryBase};
    }
  }
`;

const StyleMenuRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 134px;
  min-height: 22px;
`;

const PrimaryMobileNav: FC<MobileNavbarProps> = ({ showDrawer }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const isMobile = useMobileView(576);

  const { pathname } = router;

  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();

  if (!countryCode) {
    return null;
  }

  const showShortenedIDMenuLabel = countryCode === 'id' && i18next.language === 'id' && isMobile;

  const ITEM_NAVBAR_MOBILE = (region: TRegionKey, lang: TLanguageKey) => {
    const buyItem: MenuItem = {
      // eslint-disable-next-line no-nested-ternary
      titleTranslate: showShortenedIDMenuLabel ? t('shared.topNavigation.buyShortened') : t('shared.topNavigation.buy'),
      key: 'buy',
      url: URL_CONFIG({ region, lang }).buy,
      pathName: '/',
    };

    const sellItem: MenuItem = {
      titleTranslate: showShortenedIDMenuLabel
        ? t('shared.topNavigation.sellShortened')
        : t('shared.topNavigation.sell'),
      key: 'sell',
      url: URL_CONFIG({ region, lang }).sell,
      pathName: '/sell',
    };

    if (region === 'sg') {
      const buyNewItem: MenuItem = {
        titleTranslate: t('shared.topNavigation.newCar'),
        key: 'buyNew',
        url: URL_CONFIG({ region: 'sg', lang }).buyNew,
        pathName: '/buy-new',
      };

      if (isMobile) {
        return [
          // {
          //   titleTranslate: t('shared.topNavigation.buy'),
          //   key: 'buyMenu',
          //   // subMenus: [buyItem],
          // } as MenuItem,
          buyItem,
          sellItem,
          buyNewItem,
        ];
      }

      return [buyItem, sellItem, buyNewItem];
    }

    if (region === 'my') {
      const items = [buyItem, sellItem];

      if (!isMobile) {
        items.push({
          titleTranslate: t('shared.topNavigation.workshop'),
          key: 'workshop',
          url: URL_CONFIG({ region: 'my', lang, path: 'workshop' }).formatted,
          pathName: '/refurb',
        });
      }

      return items;
    }

    return [buyItem, sellItem];
  };

  const renderRightItems = (region: TRegionKey, lang: TLanguageKey) => (
    <StyledRightItems>
      {ITEM_NAVBAR_MOBILE(region, lang).map((item) => {
        if (item.subMenus) {
          return <PrimaryMobileNavSubmenu item={item} />;
        }

        const isActiveLink = pathname === item.pathName;
        return (
          <a href={item.url} key={item.key} className={`mobile-navbar-a ${isActiveLink ? 'active' : ''}`}>
            {item.titleTranslate}
          </a>
        );
      })}
    </StyledRightItems>
  );

  return (
    <StyleWrapper>
      <StyleMenuLeft>
        <button type="button" className="mobile-nav-hamburger" onClick={showDrawer}>
          <Image alt="menu" src={staticImgs.hamburgerIcon} width={22} height={22} />
        </button>
        <a href={URL_CONFIG({ region: countryCode, lang: i18next.language as TLanguageKey }).home}>
          <Image src={staticImgs.carroLogo} alt="logo" width={96} height={24} />
        </a>
      </StyleMenuLeft>
      <StyleMenuRight>
        {renderRightItems(countryCode, i18next.language as TLanguageKey)}
        <MultiLanguage />
      </StyleMenuRight>
    </StyleWrapper>
  );
};

export default PrimaryMobileNav;
