/* eslint-disable @typescript-eslint/no-empty-function */
// Due to eslint request a native interactive elements whereas if we use the button at this position--> the layout will be crashed
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { Typography } from '@design-system/components/Typography';
import StyledContainer from '@design-system/styled/Container';
import { TRegionKey } from '@source/interface';
import MultiLanguage from '@source/pages/Home/components/MultiLanguage';
import DownloadComponent from '@source/pages/Home/components/DownloadComponent';
import FlagRegionSelector from '@source/pages/Home/components/FlagRegionSelector';
import {
  BrandLogoProps,
  CarItem,
  FloatMenuProps,
  ItemMenuProps,
} from '@source/components/TopNavigation/DesktopNavigation/DesktopNavigationProps';
import { BLOG_URL, SUPPORTED_LANGUAGES_MAP_BY_COUNTRY } from '@source/constants/config';
import { URL_CONFIG } from '@source/constants/urlConfig';
import LoginContainer from '@design-system/components/Login/LoginContainer';
import UserIcon from '@source/components/TopNavigation/assets/UserIcon';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import AuthSmallItem from '@source/components/TopNavigation/DesktopNavigation/AuthSmallItem';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { TOP_NAV_ACCOUNT } from '@source/components/TopNavigation/constants';
import useGTMEvent from '@source/hooks/useGTMEvent';
import CustomTextWithFontFamily from '@source/components/CustomTextWithFontFamily';
import { CARRO_CARE_PHONE_NUMBER } from '@source/constants/common';

const StyleContainer = styled.div`
  box-shadow: ${(props) => props.theme.color.boxShadow.cardRaiseMiddleShadow};
  background-color: ${(props) => props.theme.color.background};
`;

const StyleWrapper = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  height: 64px;
  background-color: ${(props) => props.theme.color.background};
`;

const StyleMenuRight = styled.div`
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 701px;
  min-height: 49px;
`;

const StyleMenuLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`;

const StyledSmallItemWithDropDown = styled.div`
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
  }

  &:first-child {
    &::after {
      border-top: unset;
    }
  }

  &:hover .desktop-nav-icon-image {
    color: ${(props) => props.theme.color.primaryBase};
  }
  .desk-nav-drop-down-icon {
    transform: rotate(180deg);
    color: ${(props) => props.theme.color.primaryBase};
  }

  .desk-nav-drop-down-icon {
    transition: transform 0.3s ease;
    color: ${(props) => props.theme.color.primarySubtle};
  }

  .desktop-nav-dropdown {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    ${(props) => props.theme.typo.style.body}
    padding: 13px 18px;

    .desktop-nav-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-right: 10px;

      .desktop-nav-icon-image {
        width: 100%;
      }
    }

    .desktop-nav-dropdown-right {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .desktop-nav-title {
        margin-right: 8px;
      }

      .desktop-nav-icon-2 {
        color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
        ${(props) => props.theme.typo.style.smallButtonOrLink}
      }

      span {
        cursor: pointer;
      }
    }

    &:hover .desktop-nav-title {
      color: ${(props) => props.theme.color.primaryBase};
    }
  }

  .desk-nav-icon-dropdown {
    display: none;

    &.desk-nav-icon-dropdown-show {
      display: block;
    }

    .desk-nav-icon-dropdown-title {
      ${(props) => props.theme.typo.familyGoogle.regular};
      color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
      margin-left: 40px;
      padding: 13px 0px;
      ${(props) => props.theme.typo.style.body};
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 85%;
        left: 42%;
        transform: translateX(-50%);
        border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
      }

      &:hover {
        color: ${(props) => props.theme.color.primaryBase};
      }
    }
  }
`;

const StyledSmallItem = styled.div<{
  hasTextWhite?: boolean;
  isFontSmall?: boolean;
  isDefaultHover?: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
  }

  &:first-child {
    &::after {
      border-top: unset;
    }
  }

  .desktop-nav-icon-image {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  span {
    cursor: pointer;
  }

  &:hover {
    cursor: ${(props) => (props.isDefaultHover ? 'default !important' : 'pointer')};
  }

  &:hover .desktop-nav-icon-image {
    color: ${(props) => props.theme.color.primaryBase};
  }

  ${(props) => props.theme.typo.style.body}
  padding: 13px 18px;

  .desktop-nav-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    ${(props) => props.theme.typo.style.sHeader}
    height: 100%;
  }

  .desktop-nav-title {
    color: ${(props) => props.hasTextWhite && props.theme.color.background};
    ${(props) =>
      props.isFontSmall &&
      css`
        ${props.theme.typo.familyGoogle.regular}
      `};

    .desktop-nav-title-a {
      color: ${(props) => props.hasTextWhite && props.theme.color.background};
    }
  }

  &:hover .desktop-nav-title {
    color: ${(props) =>
      props.isDefaultHover ? props.theme.color.onBackgroundHighEmphasis : props.theme.color.primaryBase};
  }

  .user-icon {
    fill: ${(props) => props.theme.color.background};
  }
`;

const StyledCallUsAt = styled.div<{ isSpecialCss?: boolean }>`
  padding-left: ${(props) => (props.isSpecialCss ? '20px' : '10px')};
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  position: relative;

  span {
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  }

  &:hover {
    cursor: pointer;
  }

  .desktop-nav-title-call {
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    ${(props) => props.theme.typo.style.label}
    white-space: nowrap;
  }

  .desktop-nav-title-about {
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    white-space: nowrap;

    a {
      color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    }

    a:hover {
      color: ${(props) => props.theme.color.primaryBase};
    }
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5px;
    height: 60%;
    background-color: ${(props) => (props.isSpecialCss ? props.theme.color.onBackgroundLowEmphasis : 'unset')};
  }
`;

const StyledAccountButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.primaryBase};

  .desktop-nav-account {
    margin-right: 2px;
    margin-left: 8px;
  }

  .text {
    margin-left: 0.5rem;
  }
`;

const StyledMenuRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  ${(props) => props.theme.typo.style.mainButtonOrLink}
`;

const StyledFloatSubMenu = styled.div<{ hasBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgb(33 33 33 / 50%) 0px 4px 25px;
  width: 270px;
  background: ${(props) => (props.hasBackground ? props.theme.color.primaryBase : '')};

  .desk-nav-buy-buton .desk-nav-drop-down-icon {
    color: ${(props) => (props.hasBackground ? props.theme.color.primaryBase : '')};
  }

  .desktop-nav-icon-image-logo {
    background-color: transparent;
    color: ${(props) => props.theme.color.onPrimary};
    border: 1px solid ${(props) => props.theme.color.onPrimary};
    margin-right: 10px;
  }

  .desktop-normal-item {
    .desktop-nav-title-a {
      color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    }

    &:hover .desktop-nav-title-a {
      color: ${(props) => props.theme.color.primaryBase};
    }
  }
  .desktop-normal-item:hover {
    cursor: pointer;
    background: ${(props) => (props.hasBackground ? props.theme.color.primaryHover : '')};
  }

  .desktop-normal-item:hover .desktop-nav-icon-image-logo {
    color: ${(props) => props.theme.color.primaryBase};
    border: 1px solid ${(props) => props.theme.color.primaryBase};
  }

  .desktop-nav-title-with-url {
    width: 100%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
    }

    &:first-child {
      &::after {
        display: none;
      }
    }
  }

  ${(props) =>
    props.hasBackground &&
    css`
      .desktop-normal-item {
        &:hover {
          .desktop-nav-title {
            color: ${props.theme.color.background};
          }
        }
      }
    `}
`;

const StyleDropDownButtonSection = styled.div`
  position: relative;
  z-index: 999;

  &:hover .desk-nav-drop-down-icon {
    transform: rotate(180deg);
  }

  .desk-nav-buy-buton {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};

    &:hover {
      color: ${(props) => props.theme.color.primaryBase};
    }

    .desk-nav-drop-down-icon {
      margin-top: 2px;
      margin-left: 4px;
      transition: transform 0.3s ease;
      ${(props) => props.theme.typo.style.captionRegular};

      &.activeIcon {
        color: ${(props) => props.theme.color.primaryBase};
      }
    }

    &.activeLink {
      color: ${(props) => props.theme.color.primaryBase};
    }
  }

  &:hover .desk-nav-sub-menu {
    display: block;
  }

  .desk-nav-sub-menu {
    z-index: 1000;
    background-color: ${(props) => props.theme.color.background};
    display: none;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px;
    border-radius: 12px;
    background-color: ${(props) => props.theme.color.background};
    box-shadow: rgb(33 33 33 / 50%) 0px 4px 25px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 110%;
    height: 50px;
    left: 0;
    bottom: -30px;
  }

  &:hover .desk-nav-buy-buton {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const StyledItemMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.color.primaryBase};
  }

  .desktop-nav-title-a {
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    &.activeLink {
      color: ${(props) => props.theme.color.primaryBase};
    }
  }

  &:hover .desktop-nav-title-a {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const SmallItemWithDropdown = ({ subItem }: { subItem: CarItem }) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const { t: translation } = useTranslation();
  const subItemTitle = subItem?.title || translation(subItem?.titleTranslate as string);
  const toggleNavBar = () => {
    setIsCollapse(!isCollapse);
  };

  return (
    <StyledSmallItemWithDropDown>
      <div className="desktop-nav-dropdown" onClick={toggleNavBar}>
        <div className="desktop-nav-icon">
          {subItem.icon && (
            <Image
              src={subItem.icon}
              alt={subItem.key}
              width={16}
              height={16}
              className="desktop-nav-icon-image"
              layout="fixed"
            />
          )}
        </div>
        <div className="desktop-nav-dropdown-right">
          <Typography variant="mainButtonOrLink" className="desktop-nav-title">
            {subItemTitle}
          </Typography>
          <div className={`${isCollapse ? 'desk-nav-drop-down-icon' : ''}`}>
            <DownOutlined className="desktop-nav-icon-2 " />
          </div>
        </div>
      </div>

      <div className={`${isCollapse ? 'desk-nav-icon-dropdown-show' : ''} desk-nav-icon-dropdown`}>
        {subItem.children?.map((item) => {
          const childTitle = item?.title || translation(item?.titleTranslate as string);
          return item?.url ? (
            // <Link href={item.url} prefetch={false} passHref key={item.key}>
            <a href={item.url} key={item.key} rel="noopener noreferrer">
              <div className="desk-nav-icon-dropdown-title">{childTitle}</div>
            </a>
          ) : (
            // </Link>
            <div className="desk-nav-icon-dropdown-title" key={item.key}>
              {childTitle}
            </div>
          );
        })}
      </div>
    </StyledSmallItemWithDropDown>
  );
};

const SmallNormalItem = ({ subItem }: { subItem: CarItem }) => {
  const { t: translation } = useTranslation();

  const title = subItem?.title || translation(subItem?.titleTranslate as string);
  const { key } = subItem;

  const { userProfileState, showLoginModal, isAuthenticated } = useUserProfileServices();
  const { profile } = userProfileState;
  const isAuth = isAuthenticated();

  const isLoginItem = key === 'login';
  const isAuthUserItem = isAuth && isLoginItem;

  // Todo_lich: help me move below code relate to login into new container/components

  if (isAuthUserItem) {
    return <AuthSmallItem profile={profile} />;
  }

  return (
    <StyledSmallItem
      hasTextWhite={subItem?.textWhite}
      isFontSmall={subItem?.isFontSmall}
      isDefaultHover={subItem?.content === 'download' || subItem?.content === 'region'}
      className="desktop-normal-item"
      onClick={() => {
        if (isLoginItem) showLoginModal();
      }}
    >
      {subItem.icon && (
        <div className="desktop-nav-icon">
          <Image src={subItem.icon} alt={subItem.key} width={16} height={16} layout="fixed" />
        </div>
      )}
      {isLoginItem && (
        <div className="desktop-nav-icon">
          <UserIcon width={15} height={15} />
        </div>
      )}
      <Typography variant={subItem.isFontSmall ? 'body' : 'mainButtonOrLink'} className="desktop-nav-title">
        {title && title}
        {subItem?.content === 'download' ? <DownloadComponent /> : null}
        {subItem?.content === 'region' ? <FlagRegionSelector /> : null}
      </Typography>
    </StyledSmallItem>
  );
};

const SmallItem = ({ subItem }: { subItem: CarItem }) =>
  subItem.children ? <SmallItemWithDropdown subItem={subItem} /> : <SmallNormalItem subItem={subItem} />;

const FloatSubMenu: FC<FloatMenuProps> = ({ listItems, hasBackground }) => {
  const { showLoginModal, redirectAfterVerified } = useUserProfileServices();

  const handleClickSubItem = (item: BrandLogoProps) => {
    showLoginModal();
    if (item.redirectTo) {
      redirectAfterVerified({ url: item.redirectTo });
    }
  };

  return (
    <StyledFloatSubMenu hasBackground={hasBackground}>
      {listItems.map((subItem) => {
        if (subItem?.showLoginModal) {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={subItem.key}
              onClick={() => handleClickSubItem(subItem)}
              rel="noreferrer"
              className="desktop-nav-title-with-url"
            >
              <SmallItem subItem={subItem} />
            </a>
          );
        }

        if (subItem?.url) {
          return (
            // <Link href={subItem.url} passHref prefetch={false} key={subItem.key}>
            <a key={subItem.key} href={subItem.url} rel="noreferrer" className="desktop-nav-title-with-url">
              <SmallItem subItem={subItem} />
            </a>
          );
        }

        return (
          // </Link>
          <SmallItem subItem={subItem} key={subItem.key} />
        );
      })}
    </StyledFloatSubMenu>
  );
};

const DropDownMenuItem: FC<any> = ({ title, subMenus, hasBackground, isActiveLink }) => {
  const dropdownlist = <FloatSubMenu listItems={subMenus} hasBackground={hasBackground} />;
  return (
    <StyleDropDownButtonSection>
      <div className={`desk-nav-buy-buton ${isActiveLink ? 'activeLink' : ''}`}>
        {title}
        <div className={`desk-nav-drop-down-icon ${hasBackground ? 'activeIcon' : ''}`}>
          <CaretDownOutlined />
        </div>
      </div>
      <div className="desk-nav-sub-menu">{dropdownlist}</div>
    </StyleDropDownButtonSection>
  );
};

const TranslateTextContent: FC<any> = ({ titleTranslate }: { titleTranslate: string }) => {
  const { t: translatetion } = useTranslation();
  return <>{translatetion(titleTranslate)}</>;
};

const ItemMenuNew = ({ itemMenu }: { itemMenu: ItemMenuProps }) => {
  const router = useRouter();
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();
  const { t } = useTranslation();
  const { title, titleTranslate, type, subMenus, icon, key, url, activeLink } = itemMenu;
  const isActiveLink = router.pathname === activeLink || false;

  const textContent = title || <Trans defaults={titleTranslate ? t(titleTranslate) : ''} />;

  const contentWithURL = url ? (
    <div className={`desktop-nav-title-a ${isActiveLink ? 'activeLink' : ''}`}>{textContent}</div>
  ) : (
    textContent
  );

  const { userProfileState, isAuthenticated } = useUserProfileServices();
  const { profile } = userProfileState;

  const isAuth = isAuthenticated();
  const isMY = countryCode === 'my';
  const isAccount = key === 'account';

  const isAuthMYAccount = isMY && isAccount && isAuth;

  const textNIcon = (
    <StyledAccountButton>
      <Image src={icon || ''} width={25} height={25} alt={titleTranslate ? t(titleTranslate) : ''} priority />
      <span className="text">{isAuth ? profile?.name : textContent}</span>
    </StyledAccountButton>
  );
  const content = icon ? textNIcon : textContent;

  return (
    <StyledItemMenu className={`${type} HeaderNavItem`}>
      {type && type === 'dropdown' ? (
        <DropDownMenuItem
          title={content}
          hasBackground={key === 'account' && !isAuth}
          subMenus={isAuthMYAccount ? TOP_NAV_ACCOUNT : subMenus}
          isActiveLink={isActiveLink}
        />
      ) : (
        contentWithURL
      )}
    </StyledItemMenu>
  );
};

const MenuRight: FC<{ renderItems: Array<ItemMenuProps> }> = ({ renderItems }) => {
  const { showLoginModal, redirectAfterVerified } = useUserProfileServices();

  const handleClickSubItem = (item: ItemMenuProps) => {
    showLoginModal();
    if (item.redirectTo) {
      redirectAfterVerified({ url: item.redirectTo });
    }
  };

  return (
    <StyledMenuRight>
      {(renderItems || []).map((item) => {
        if (item?.showLoginModal) {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={() => handleClickSubItem(item)} rel="noreferrer" key={item.key} className="desktop-nav-title-a">
              <ItemMenuNew itemMenu={item} />
            </a>
          );
        }

        if (item.url) {
          return (
            <a
              href={item.url}
              rel="noreferrer"
              key={item.key}
              className="desktop-nav-title-a"
              target={item?.isExternal ? '_blank' : '_self'}
            >
              <ItemMenuNew itemMenu={item} />
            </a>
          );
        }

        return <ItemMenuNew itemMenu={item} key={item.key} />;
      })}
    </StyledMenuRight>
  );
};

const CallUsAt: FC<any> = ({ phoneNumber, countryCode }) => {
  const { t } = useTranslation();
  const { pushGTMEvent } = useGTMEvent();

  const countrySupportOnly1Language = Object.entries(SUPPORTED_LANGUAGES_MAP_BY_COUNTRY)
    .filter(([, supportedCountries]) => supportedCountries.length <= 1)
    .map(([country]) => country);

  const handlePushGTMEvent = () => {
    pushGTMEvent({
      event: 'contact_call',
    });
  };

  return (
    <StyledCallUsAt className="call-us" isSpecialCss={countrySupportOnly1Language.includes(countryCode)}>
      <Typography variant="label" className="desktop-nav-title-call">
        {t('shared.topNavigation.callus')}
      </Typography>
      <Typography variant="sHeader" className="desktop-nav-title-about">
        <a onClick={handlePushGTMEvent} href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </a>
      </Typography>
    </StyledCallUsAt>
  );
};

interface SharedDesktopNavigationProps {
  headerConfigs: any;
  countryCode: TRegionKey | undefined;
}

const SharedDesktopNavigation: FC<SharedDesktopNavigationProps> = ({ headerConfigs, countryCode }) => {
  const router = useRouter();
  const isMYRefurbPage = router.pathname.includes('/refurb') && countryCode === 'my';

  return (
    <StyleContainer>
      <StyleWrapper>
        <StyleMenuLeft>
          <a href={URL_CONFIG({ region: countryCode as TRegionKey, lang: i18next.language }).home}>
            <Image src={headerConfigs?.brandLogo?.src} alt="logo" width={122} height={32} priority />
          </a>
        </StyleMenuLeft>
        <StyleMenuRight>
          <MenuRight renderItems={headerConfigs?.rightMenu} />
          <MultiLanguage />
          <CallUsAt
            phoneNumber={isMYRefurbPage ? CARRO_CARE_PHONE_NUMBER.my : headerConfigs?.phone}
            countryCode={countryCode}
          />
        </StyleMenuRight>
      </StyleWrapper>
      <LoginContainer />
    </StyleContainer>
  );
};

export default SharedDesktopNavigation;
