import { Trans } from 'react-i18next';

import { getStaticCDN, onKeyDownNumberOnly } from '@design-system/utils/utils';
import { TRegionKey } from '@source/interface';
import { IFormItemProps } from '@source/interface/form';

export type IFormInputKey =
  | 'phone'
  | 'name'
  | 'makeModel'
  | 'email'
  | 'agreement'
  | 'otp_channel'
  | 'accept_marketing_updates';

type IFormInput = {
  [key in IFormInputKey]: IFormItemProps;
};

interface IForm {
  label?: string;
  requiredMessage?: string;
  placeholder?: string;
}

export interface IMobileNumberForm extends IForm {
  label?: string;
  pattern?: RegExp;
  patternMessage?: string;
  requiredMessage?: string;
  placeholder?: string;
}

interface INameForm extends IForm {
  label?: string;
  requiredMessage?: string;
  placeholder?: string;
}

interface IEmailForm extends IForm {
  label?: string;
  typeMessage?: string;
  requiredMessage?: string;
  placeholder?: string;
}

interface IAgreementForm extends IForm {
  requiredMessage?: string;
  placeholder?: string;
}

export type IFormByCountry<T extends IForm> = Record<TRegionKey, T>;

export const PHONE_VALIDATION: IFormByCountry<IMobileNumberForm> = {
  sg: {
    label: 'shared.forms.mobileNumber.sg.label',
    pattern: /^[89]\d{7}$/g,
    patternMessage: 'shared.forms.mobileNumber.sg.patternMessage',
    requiredMessage: 'shared.forms.mobileNumber.sg.requiredMessage',
    placeholder: 'shared.forms.mobileNumber.sg.placeholder',
  },
  th: {
    label: 'shared.forms.mobileNumber.th.label',
    pattern: /^[0][6|8|9]\d{8}$/g,
    patternMessage: 'shared.forms.mobileNumber.th.patternMessage',
    requiredMessage: 'shared.forms.mobileNumber.th.requiredMessage',
    placeholder: 'shared.forms.mobileNumber.th.placeholder',
  },
  id: {
    label: 'shared.forms.mobileNumber.id.label',
    pattern: /^(\+62|62|0)8[0-9]{8,11}$/g,
    patternMessage: 'shared.forms.mobileNumber.id.patternMessage',
    requiredMessage: 'shared.forms.mobileNumber.id.requiredMessage',
    placeholder: 'shared.forms.mobileNumber.id.placeholder',
  },
  my: {
    label: 'shared.forms.mobileNumber.my.label',
    pattern: /^((0|\+60)|60)[0-9]{9,10}$/g,
    patternMessage: 'shared.forms.mobileNumber.my.patternMessage',
    requiredMessage: 'shared.forms.mobileNumber.my.requiredMessage',
    placeholder: 'shared.forms.mobileNumber.my.placeholder',
  },
  jp: {
    label: 'shared.forms.mobileNumber.jp.label',
    pattern: /^[0][7|8|9][0]\d{8}$/g,
    patternMessage: 'shared.forms.mobileNumber.jp.patternMessage',
    requiredMessage: 'shared.forms.mobileNumber.jp.requiredMessage',
    placeholder: 'shared.forms.mobileNumber.jp.placeholder',
  },
  hk: {
    label: 'shared.forms.mobileNumber.hk.label',
    pattern: /^(\+?852-?)?\d{4}-?\d{4}$/g,
    patternMessage: 'shared.forms.mobileNumber.hk.patternMessage',
    requiredMessage: 'shared.forms.mobileNumber.hk.requiredMessage',
    placeholder: 'shared.forms.mobileNumber.hk.placeholder',
  },
};

export const NAME_VALIDATION: INameForm = {
  label: 'shared.forms.name.label',
  requiredMessage: 'shared.forms.name.requiredMessage',
  placeholder: 'shared.forms.name.placeholder',
};

export const MAKE_MODEL_VALIDATION: INameForm = {
  label: 'shared.forms.makeModel.label',
  requiredMessage: 'shared.forms.makeModel.requiredMessage',
  placeholder: 'shared.forms.makeModel.placeholder',
};

export const EMAIL_VALIDATION: IEmailForm = {
  label: 'shared.forms.email.label',
  typeMessage: 'shared.forms.email.typeMessage',
  requiredMessage: 'shared.forms.email.requiredMessage',
  placeholder: 'shared.forms.email.placeholder',
};

export const AGREEMENT_VALIDATION: IAgreementForm = {
  requiredMessage: 'shared.forms.agreement.requiredMessage',
};

export const VALIDATIONS: Record<IFormInputKey, any> = {
  phone: PHONE_VALIDATION,
  name: NAME_VALIDATION,
  email: EMAIL_VALIDATION,
  makeModel: MAKE_MODEL_VALIDATION,
  agreement: AGREEMENT_VALIDATION,
  otp_channel: undefined,
  accept_marketing_updates: undefined,
};

// TODO: Refactor for another keys
export const getPhoneValidation = (country: TRegionKey, props?: IMobileNumberForm): Record<string, any> => ({
  [country]: {
    ...PHONE_VALIDATION[country],
    ...props,
  },
});

export const getFormInput = (region: TRegionKey, t: any, validations = VALIDATIONS): IFormInput => ({
  phone: {
    type: 'input',
    formItemProps: {
      name: 'phone',
      label: t(validations.phone?.[region]?.label) || 'Mobile Number',
      rules: [
        {
          pattern: validations.phone?.[region]?.pattern || /^[89]\d{7}$/g,
          message:
            t(validations.phone?.[region]?.patternMessage) || 'Please enter a 8-digit phone no. starting with 8 or 9!',
        },
        {
          required: true,
          message:
            t(validations.phone?.[region]?.requiredMessage) || 'Please enter a 8-digit phone no. starting with 8 or 9!',
        },
      ],
    },
    controlProps: {
      size: 'large',
      placeholder: t(validations.phone?.[region]?.placeholder) || 'E.g. 9123 4567',
      inputMode: 'tel',
      type: 'number',
      className: 'no-controls',
      onKeyDown: onKeyDownNumberOnly,
    },
  },
  name: {
    type: 'input',
    formItemProps: {
      name: 'name',
      label: t(validations.name?.label) || 'Name',
      rules: [
        {
          required: true,
          message: t(validations.name.requiredMessage) || 'Please enter your name',
        },
      ],
    },
    controlProps: {
      size: 'large',
      placeholder: t(validations.name.placeholder) || 'E.g. John Doe',
    },
  },
  makeModel: {
    type: 'input',
    formItemProps: {
      name: 'makeModel',
      label: t(validations.makeModel.label) || 'Make Model',
      rules: [
        {
          required: true,
          message: t(validations.makeModel?.requiredMessage) || 'Please enter Make Model',
        },
      ],
    },
    controlProps: {
      size: 'large',
      placeholder: t(validations.makeModel?.placeholder) || 'E.g : Toyota Avanza 2020',
    },
  },
  email: {
    type: 'input',
    formItemProps: {
      name: 'email',
      label: t(validations.email?.label) || 'Email',
      rules: [
        {
          type: 'email',
          message: t(validations.email?.typeMessage) || 'Please enter a valid email address.',
        },
        {
          required: true,
          message: t(validations.email?.requiredMessage) || 'Please enter your email address.',
        },
      ],
    },
    controlProps: {
      size: 'large',
      placeholder: t(validations.email?.placeholder) || 'Email',
    },
  },
  agreement: {
    type: 'checkbox',
    formItemProps: {
      name: 'agree_tnc',
      rules: [
        {
          required: true,
          message:
            t(validations.agreement?.requiredMessage) ||
            'You must agree with the Terms and Conditions and the Privacy Policy before you submit.',
        },
      ],
    },
    options: [], // agreement options will need to be passed from component itself due to JSX element
    controlProps: {
      size: 'large',
    },
  },
  otp_channel: {
    type: 'checkbox',
    key: 'otp_channel',
    formItemProps: {
      name: 'otp_channel',
    },
    options: [
      {
        label: (
          <Trans
            i18nKey="shared.login.receiveOTPOnWA"
            components={{
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content, @next/next/no-img-element
              img: <img src={getStaticCDN('/assets/shared/user-profile/whatsapp-icon-v2.svg')} alt="whatsapp-icon" />,
              bold: <b className="bold" />,
            }}
          />
        ),
        value: 'whatsapp',
      },
    ],
    controlProps: {},
  },
  accept_marketing_updates: {
    type: 'checkbox',
    formItemProps: {
      name: 'accept_marketing_updates',
    },
    options: [
      {
        label: <Trans i18nKey={`shared.forms.accept_marketing_updates.label.${region}`} />,
        value: true,
      },
    ],
  },
});
